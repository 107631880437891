import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/sftsk/Sites/boonconcom/src/components/podcasts-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Intro`}</h3>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/saftsaak"
      }}>{`@saftsaak`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/metzelfetz"
      }}>{`@metzelfetz`}</a></p>
    <h3>{`Nordic Startup Environment`}</h3>
    <p><a parentName="p" {...{
        "href": "http://startupsauna.com"
      }}>{`Startup Sauna`}</a>{` – `}<a parentName="p" {...{
        "href": "http://cphftw.dk"
      }}>{`#cphftw`}</a>{` – `}<a parentName="p" {...{
        "href": "http://techcrunch.com/2013/12/03/rovio-supercell-finland/"
      }}>{`Helsinki Gaming Scene`}</a>{` – `}<a parentName="p" {...{
        "href": "http://eu.getcatchbox.com"
      }}>{`Catchbox`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.leeluu.fi"
      }}>{`LeeLuu`}</a></p>
    <h3>{`AWSome Day Helsinki`}</h3>
    <p><a parentName="p" {...{
        "href": "https://aws.amazon.com"
      }}>{`AWS`}</a>{` – `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/events/awsome-day-roadshow-mar-2015/"
      }}>{`Roadshow 2015`}</a>{` – `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/redshift/"
      }}>{`Redshift`}</a>{` – `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/summits/stockholm/faqs/"
      }}>{`AWS Summit Stockholm 2015`}</a></p>
    <h3>{`A little bit of sports`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.toughest.se/en/events/copenhagen/"
      }}>{`Toughest`}</a>{` – `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Disc_golf"
      }}>{`Disc Golf`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.innovadiscs.com/targets/skillshot.html"
      }}>{`Portable disc golf basket`}</a></p>
    <h3>{`Lazer eyes`}</h3>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Photorefractive_keratectomy"
      }}>{`PRK`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.lasiksurgerynews.com/news/flex.shtml"
      }}>{`SMILE`}</a>{` – `}<a parentName="p" {...{
        "href": "http://vision.about.com/od/eyeexaminations/ig/Eye-Exam-Equipment/"
      }}>{`Eye Exam Equipment`}</a></p>
    <h3>{`TV show recommendations`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt3749900/"
      }}>{`Gotham`}</a>{` – `}<a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt2085059/"
      }}>{`Black Mirror`}</a></p>
    <h3>{`Rant of the week: Google Chromebox for meetings`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.google.com/work/chrome/devices/for-meetings/index.html"
      }}>{`Chromebox for meetings`}</a></p>
    <h3>{`Outro`}</h3>
    <p><a parentName="p" {...{
        "href": "http://booncon.com/podcast"
      }}>{`booncon.com/podcast`}</a>{` – `}<a parentName="p" {...{
        "href": "https://twitter.com/booncon"
      }}>{`@booncon`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      